import React, { useEffect, useState } from "react";
import SignoutButton from "../auth/SignoutButton";
import Spinner from "react-bootstrap/Spinner";
import NavWrapper from "../nav/NavWrapper";
import Button from "react-bootstrap/Button";
import Emoji from "./Emoji";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { Col } from "react-bootstrap";
import FontAwesome from "@wellingtonsteve/jscommon/FontAwesome";
import useCollectionSubscription from "@wellingtonsteve/jscommon/useCollectionSubscription";
import useCutoffDate from "@wellingtonsteve/jscommon/useCutoffDate";
import { dateToTimestamp, secondsSince, timestampNow } from "@wellingtonsteve/jscommon/timestampUtils";

const If = NavWrapper.If;

const Loading = () => (
  <div>
    <Spinner animation="grow" variant="danger" /> <Spinner animation="grow" variant="warning" /> <Spinner animation="grow" variant="info" />{" "}
    <Spinner animation="grow" variant="success" />
  </div>
);

const LoadingWithCaption = ({ caption }) => (
  <div style={{ width: "100%", textAlign: "center", margin: "1rem" }}>
    <Loading />
    <h1>{caption}</h1>
  </div>
);

const defaultCutOffDate = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2);
};

const LoggedInApp = ({ logOutAction, currentUser, firestore, datasetDoc }) => {
  if (datasetDoc === undefined) {
    return <LoadingWithCaption caption={"Hi " + currentUser.displayName + ". Logging in..."} />;
  } else {
    return <DatasetDocLoaded logOutAction={logOutAction} currentUser={currentUser} firestore={firestore} datasetDoc={datasetDoc} />;
  }
};

const DatasetDocLoaded = ({ logOutAction, currentUser, firestore, datasetDoc }) => {
  const newObjectSink = (newObjectsToAdd) => {
    const batch = firestore.batch();
    newObjectsToAdd.forEach((newObjectToAdd) => batch.set(contentCollection.doc(), newObjectToAdd));
    return batch.commit();
  };

  const { cutOffDate, cutOffCollection, cutoffActions } = useCutoffDate(3, defaultCutOffDate, datasetDoc, "content");
  const [contentDocs, loadingMore] = useCollectionSubscription(cutOffCollection, undefined, "content (" + cutOffDate + ")");

  const contentCollection = datasetDoc.collection("content");
  if (contentDocs === undefined) {
    return <LoadingWithCaption caption={"Hi " + currentUser.displayName + ". Loading..."} />;
  } else {
    return (
      <NavWrapper title="Baby Log">
        <If
          tabName="Feeds"
          default
          ifSelectedShow={() => (
            <FeedsView
              contentDocs={contentDocs}
              contentCollection={contentCollection}
              cutOffDate={cutOffDate}
              loadingMore={loadingMore}
              cutoffActions={cutoffActions}
            />
          )}
        />
        <If tabName="Import" ifSelectedShow={() => <Import newObjectSink={newObjectSink} />} />
        <If tabName="Export" ifSelectedShow={() => <Export contentDocs={contentDocs} />} />
        <SignoutButton onClick={logOutAction} />
      </NavWrapper>
    );
  }
};

const weekdays = new Array(7);
weekdays[0] = "Sun";
weekdays[1] = "Mon";
weekdays[2] = "Tues";
weekdays[3] = "Weds";
weekdays[4] = "Thurs";
weekdays[5] = "Fri";
weekdays[6] = "Sat";

const formatDate = (date) => {
  let day = "" + date.getDate();
  let month = "" + (date.getMonth() + 1);
  let year = date.getFullYear();

  if (day.length < 2) day = "0" + day;
  if (month.length < 2) month = "0" + month;

  return weekdays[date.getDay()] + " " + [day, month, year].join("/");
};

const FeedsView = ({ contentCollection, contentDocs, cutOffDate, cutoffActions, loadingMore }) => {
  const [selectedDocId, setSelectedDocId] = useState(undefined);
  const docToAugmentedObject = (feedDoc) => {
    const startDate = feedDoc.data().startTime.toDate();
    return {
      ...feedDoc.data(),
      id: feedDoc.id,
      formattedStartDate: formatDate(startDate),
    };
  };

  const sortedFeeds = contentDocs.map(docToAugmentedObject).sort((feed1, feed2) => feed2.startTime._compareTo(feed1.startTime));

  const onlyUnique = (value, index, self) => self.indexOf(value) === index;
  const allDates = sortedFeeds.map((feed) => feed.formattedStartDate).filter(onlyUnique);

  const lastFeed = sortedFeeds[0];
  const inProgressSide = lastFeed === undefined ? undefined : lastFeed.endTime === undefined ? lastFeed : undefined;

  return (
    <div>
      <BoobButton contentCollection={contentCollection} side="Left" inProgressSide={inProgressSide} />{" "}
      <BoobButton contentCollection={contentCollection} side="Right" inProgressSide={inProgressSide} />{" "}
      <BoobButton contentCollection={contentCollection} side="Nap" inProgressSide={inProgressSide} />{" "}
      <BoobButton contentCollection={contentCollection} side="Sleep" inProgressSide={inProgressSide} />
      <SinceLastSleepNap sortedFeeds={sortedFeeds} />
      {allDates.map((date) => (
        <div key={date}>
          <h1
            style={{
              fontSize: "140%",
              marginTop: ".5rem",
              marginBottom: ".5rem",
            }}
          >
            {date}
          </h1>
          {sortedFeeds
            .filter((feedObj) => feedObj.formattedStartDate === date)
            .map((feedObj) => (
              <Entry
                key={feedObj.id}
                feedObj={feedObj}
                selectedDocId={selectedDocId}
                setSelectedDocId={setSelectedDocId}
                commitUpdates={(newFields) => contentCollection.doc(feedObj.id).set(newFields, { merge: true })}
                deleteAction={() => contentCollection.doc(feedObj.id).delete()}
              />
            ))}
        </div>
      ))}
      <p
        style={{
          textAlign: "center",
          margin: "1em",
          fontSize: "90%",
        }}
      >
        {loadingMore ? <Spinner animation="grow" variant="success" /> : null}
        {cutOffDate !== null ? (
          <span>
            Hiding everything before {formatDate(cutOffDate)} <Button onClick={cutoffActions.showMoreAction}>Show more</Button>{" "}
            <Button onClick={cutoffActions.showAllAction}>Show all</Button> <Button onClick={() => cutoffActions.resetAction()}>Hide old</Button>
          </span>
        ) : (
          <Button onClick={() => cutoffActions.resetAction()}>Hide old</Button>
        )}
      </p>
    </div>
  );
};

const SinceLastSleepNap = ({ sortedFeeds }) => {
  const sleepsOrNaps = sortedFeeds.filter((feedObj) => feedObj.side === "nap" || feedObj.side === "sleep");
  const lastSleepOrNap = sleepsOrNaps.length > 0 ? sleepsOrNaps[0] : undefined;

  if (lastSleepOrNap === undefined) {
    return null;
  }

  if (lastSleepOrNap.side === "nap") {
    return <SinceLast sortedFeeds={sortedFeeds} searchFor="nap" since="sleep" name="Naps" />;
  } else {
    return <SinceLast sortedFeeds={sortedFeeds} searchFor="sleep" since="nap" name="Sleeps" />;
  }
};

const takeWhile = (f, xs) => (xs.length ? takeWhileNotEmpty(f, xs) : []);
const takeWhileNotEmpty = (f, [x, ...xs]) => (f(x) ? [x, ...takeWhile(f, xs)] : []);

const SinceLast = ({ sortedFeeds, searchFor, since, name }) => {
  const [now, setNow] = useState(timestampNow().toMillis());
  useEffect(() => {
    const id = setInterval(() => setNow(timestampNow().toMillis()), 200);
    return () => clearInterval(id);
  }, []);

  const searchResults = takeWhile((feedObj) => feedObj.side !== since, sortedFeeds).filter((feedObj) => feedObj.side === searchFor);
  const durations = searchResults.map((searchResult) => {
    const end = searchResult.endTime === undefined ? now : searchResult.endTime.toMillis();
    return Math.round((end - searchResult.startTime.toMillis()) / 1000);
  });
  const totalSeconds = durations.reduce((sum, duration) => sum + duration, 0);
  return (
    <div
      style={{
        textAlign: "center",
        color: "white",
        margin: "1em",
        fontSize: "130%",
      }}
    >
      {name} since last {since}: <Duration duration={totalSeconds} />
    </div>
  );
};

const FormattedTimestamp = ({ timestamp }) => {
  const date = timestamp.toDate();

  let hours = "" + date.getHours();
  let minutes = "" + date.getMinutes();

  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;

  return (
    <span>
      {hours}:{minutes}
    </span>
  );
};

const EmojiForSide = ({ side }) => {
  if (side === "nap") {
    return (
      <span style={{ fontSize: "200%" }}>
        <Emoji.Nap />
      </span>
    );
  } else if (side === "sleep") {
    return (
      <span style={{ fontSize: "200%" }}>
        <Emoji.Sleep />
      </span>
    );
  } else if (side === "left" || side === "right") {
    return (
      <span style={{ fontSize: "200%" }}>
        <Emoji.Feed />
      </span>
    );
  } else {
    return (
      <span style={{ fontSize: "200%" }}>
        <Emoji.Question />
      </span>
    );
  }
};

const Entry = ({ feedObj, selectedDocId, setSelectedDocId, commitUpdates, deleteAction }) => {
  const selectNoneAction = () => setSelectedDocId(undefined);
  const isInProgress = feedObj.endTime === undefined;
  const isSelected = selectedDocId === feedObj.id;
  return (
    <span>
      <table style={{ marginBottom: "0.5rem" }} onClick={() => setSelectedDocId(isSelected ? undefined : feedObj.id)}>
        <tbody>
          <tr>
            <td style={isInProgress ? { fontSize: "300%" } : {}}>
              <EmojiForSide side={feedObj.side} />
            </td>
            <td>
              {isInProgress ? (
                <span style={{ fontSize: "180%" }}>
                  <strong style={isSelected ? { background: "white", color: "#000033" } : {}}>{feedObj.side}</strong> (
                  <TimeSince startDateTime={feedObj.startTime} />)
                </span>
              ) : (
                <span>
                  <strong style={isSelected ? { background: "white", color: "#000033" } : {}}>{feedObj.side}</strong> (
                  <FormattedTimestamp timestamp={feedObj.startTime} />-
                  <FormattedTimestamp timestamp={feedObj.endTime} />)
                </span>
              )}
              <br />
              {feedObj.textContent !== undefined && feedObj.textContent !== "" ? (
                <span style={{ fontStyle: "italic" }}>
                  {feedObj.textContent}
                  <br />
                </span>
              ) : null}
              <span style={{ fontSize: "90%", color: "grey" }}>
                <IdLabel id={feedObj.id} />
                {isInProgress ? null : (
                  <span>
                    {" "}
                    • <Duration duration={Math.round((feedObj.endTime.toMillis() - feedObj.startTime.toMillis()) / 1000)} />
                    <span>
                      {" "}
                      • Ended <TimeSince startDateTime={feedObj.endTime} /> ago
                    </span>
                  </span>
                )}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      {isSelected ? (
        <span>
          <EditButton entryObj={feedObj} commitUpdates={commitUpdates} selectNoneAction={selectNoneAction} />
          <DeleteButton entryObj={feedObj} deleteAction={deleteAction} selectNoneAction={selectNoneAction} />
        </span>
      ) : null}
    </span>
  );
};

const DeleteButton = ({ entryObj, deleteAction, selectNoneAction }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    selectNoneAction();
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <span>
      <Button size="sm" style={{ marginLeft: "0.5rem" }} onClick={handleShow}>
        Delete...
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} centered style={{ color: "black" }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {entryObj.endTime === undefined ? (
              <span style={{ fontSize: "180%" }}>
                <strong style={{ background: "white", color: "#000033" }}>{entryObj.side}</strong> (
                <TimeSince startDateTime={entryObj.startTime} />)
              </span>
            ) : (
              <span>
                <strong style={{ background: "white", color: "#000033" }}>{entryObj.side}</strong> (
                <FormattedTimestamp timestamp={entryObj.startTime} />-
                <FormattedTimestamp timestamp={entryObj.endTime} />)
              </span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => deleteAction().then(handleClose)}>
            Yes, delete!
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};

const EditButton = ({ entryObj, commitUpdates, selectNoneAction }) => {
  const [show, setShow] = useState(false);
  const [editedState, setEditedState] = useState(entryObj);

  const handleClose = () => {
    setShow(false);
    selectNoneAction();
  };
  const handleShow = () => {
    setEditedState(entryObj);
    setShow(true);
  };

  const editStartTime = (dateMutator) => {
    const startTime = new Date(editedState.startTime.toDate().getTime());
    dateMutator(startTime);
    setEditedState({
      ...editedState,
      startTime: dateToTimestamp(startTime),
    });
  };

  const editEndTime = (dateMutator) => {
    const endTime = new Date(editedState.endTime.toDate().getTime());
    dateMutator(endTime);
    setEditedState({
      ...editedState,
      endTime: dateToTimestamp(endTime),
    });
  };

  return (
    <span>
      <Button size="sm" style={{ marginLeft: "0.5rem" }} onClick={handleShow}>
        Edit...
      </Button>
      <Modal show={show} onHide={handleClose} animation={false} centered style={{ color: "black" }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {entryObj.endTime === undefined ? (
              <span style={{ fontSize: "180%" }}>
                <strong style={{ background: "white", color: "#000033" }}>{entryObj.side}</strong> (
                <TimeSince startDateTime={entryObj.startTime} />)
              </span>
            ) : (
              <span>
                <strong style={{ background: "white", color: "#000033" }}>{entryObj.side}</strong> (
                <FormattedTimestamp timestamp={entryObj.startTime} />-
                <FormattedTimestamp timestamp={entryObj.endTime} />)
              </span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown onSelect={(side) => setEditedState({ ...editedState, side: side })}>
            <strong>Side/Sleep/Nap:</strong>
            {editedState.side === entryObj.side ? null : (
              <span style={{ color: "red", fontWeight: "bold" }}>
                {" "}
                Was: {entryObj.side}{" "}
                <Button size="sm" onClick={() => setEditedState({ ...editedState, side: entryObj.side })} variant="danger">
                  Reset
                </Button>
              </span>
            )}
            <br />
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {editedState.side}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {["sleep", "nap", "left", "right"].map((side) => (
                <Dropdown.Item key={side} eventKey={side} active={editedState.side === side}>
                  {side}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <strong>Comments:</strong>
          {editedState.textContent === entryObj.textContent ? null : (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {" "}
              Was: {entryObj.textContent}{" "}
              <Button
                size="sm"
                onClick={() =>
                  setEditedState({
                    ...editedState,
                    textContent: entryObj.textContent,
                  })
                }
                variant="danger"
              >
                Reset
              </Button>
            </span>
          )}
          <br />
          <Form>
            <Form.Group controlId="textContent">
              <Form.Control
                value={editedState.textContent}
                onChange={(event) =>
                  setEditedState({
                    ...editedState,
                    textContent: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
          <strong>Start Time:</strong>
          {editedState.startTime.toMillis() === entryObj.startTime.toMillis() ? null : (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {" "}
              Was: <FormattedTimestampForEditing timestamp={entryObj.startTime} />{" "}
              <Button
                size="sm"
                onClick={() =>
                  setEditedState({
                    ...editedState,
                    startTime: entryObj.startTime,
                  })
                }
                variant="danger"
              >
                Reset
              </Button>
            </span>
          )}
          <br />
          <Form>
            <Form.Row>
              <Form.Group as={Col} controlId="Day">
                <Form.Label>Day</Form.Label>
                <Form.Control
                  onChange={(event) => editStartTime((startTime) => startTime.setDate(event.target.value))}
                  value={editedState.startTime.toDate().getDate()}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} controlId="Month">
                <Form.Label>Month</Form.Label>
                <Form.Control
                  onChange={(event) => editStartTime((startTime) => startTime.setMonth(event.target.value - 1))}
                  value={editedState.startTime.toDate().getMonth() + 1}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} controlId="Year">
                <Form.Label>Year</Form.Label>
                <Form.Control
                  onChange={(event) => editStartTime((startTime) => startTime.setFullYear(event.target.value))}
                  value={editedState.startTime.toDate().getFullYear()}
                  disabled
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="Hour">
                <Form.Label>Hour</Form.Label>
                <Form.Control
                  onChange={(event) => editStartTime((startTime) => startTime.setHours(event.target.value))}
                  value={editedState.startTime.toDate().getHours()}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="Minute">
                <Form.Label>Minute</Form.Label>
                <Form.Control
                  onChange={(event) => editStartTime((startTime) => startTime.setMinutes(event.target.value))}
                  value={editedState.startTime.toDate().getMinutes()}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="Second">
                <Form.Label>Second</Form.Label>
                <Form.Control
                  onChange={(event) => editStartTime((startTime) => startTime.setSeconds(event.target.value))}
                  value={editedState.startTime.toDate().getSeconds()}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="zone">
                <Form.Label>&nbsp;</Form.Label>
                <Form.Control value={calculateZone(editedState.startTime)} disabled />
              </Form.Group>
            </Form.Row>
          </Form>
          {editedState.endTime === undefined ? null : (
            <span>
              <strong>End Time:</strong>
              {editedState.endTime.toMillis() === entryObj.endTime.toMillis() ? null : (
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {" "}
                  Was: <FormattedTimestampForEditing timestamp={entryObj.endTime} />{" "}
                  <Button
                    size="sm"
                    onClick={() =>
                      setEditedState({
                        ...editedState,
                        endTime: entryObj.endTime,
                      })
                    }
                    variant="danger"
                  >
                    Reset
                  </Button>
                </span>
              )}
              <br />
              <Form>
                <Form.Row>
                  <Form.Group as={Col} controlId="Day">
                    <Form.Label>Day</Form.Label>
                    <Form.Control
                      onChange={(event) => editEndTime((endTime) => endTime.setDate(event.target.value))}
                      value={editedState.endTime.toDate().getDate()}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="Month">
                    <Form.Label>Month</Form.Label>
                    <Form.Control
                      onChange={(event) => editEndTime((endTime) => endTime.setMonth(event.target.value - 1))}
                      value={editedState.endTime.toDate().getMonth() + 1}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="Year">
                    <Form.Label>Year</Form.Label>
                    <Form.Control
                      onChange={(event) => editEndTime((endTime) => endTime.setFullYear(event.target.value))}
                      value={editedState.endTime.toDate().getFullYear()}
                      disabled
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="Hour">
                    <Form.Label>Hour</Form.Label>
                    <Form.Control
                      onChange={(event) => editEndTime((endTime) => endTime.setHours(event.target.value))}
                      value={editedState.endTime.toDate().getHours()}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="Minute">
                    <Form.Label>Minute</Form.Label>
                    <Form.Control
                      onChange={(event) => editEndTime((endTime) => endTime.setMinutes(event.target.value))}
                      value={editedState.endTime.toDate().getMinutes()}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="Second">
                    <Form.Label>Second</Form.Label>
                    <Form.Control
                      onChange={(event) => editEndTime((endTime) => endTime.setSeconds(event.target.value))}
                      value={editedState.endTime.toDate().getSeconds()}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="zone">
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Control value={calculateZone(editedState.endTime)} disabled />
                  </Form.Group>
                </Form.Row>
              </Form>
            </span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="secondary" onClick={() => commitUpdates(editedState)}>
            Apply
          </Button>
          <Button variant="primary" onClick={() => commitUpdates(editedState).then(handleClose)}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};

const calculateZone = (timestamp) => {
  const offset = timestamp.toDate().getTimezoneOffset();
  const isGmt = offset === 0;
  const isBst = offset === -60;
  return isGmt ? "GMT" : isBst ? "BST" : offset;
};

const FormattedTimestampForEditing = ({ timestamp }) => {
  const date = timestamp.toDate();

  const zone = calculateZone(timestamp);

  let hours = "" + date.getHours();
  let minutes = "" + date.getMinutes();
  let seconds = "" + date.getSeconds();

  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;
  if (seconds.length < 2) seconds = "0" + seconds;

  return (
    <span title={timestamp.toDate().toISOString()}>
      {formatDate(timestamp.toDate())} {hours}:{minutes}:{seconds} {zone}
    </span>
  );
};

const TimeSince = ({ startDateTime }) => {
  const initialDuration = secondsSince(startDateTime);
  const overLimit = initialDuration > 24 * 60 * 60;

  const [duration, setDuration] = useState(initialDuration);

  useEffect(() => {
    if (!overLimit) {
      const id = setInterval(() => setDuration(secondsSince(startDateTime)), 200);
      return () => clearInterval(id);
    }
  }, [startDateTime, overLimit]);

  if (overLimit) {
    return <span>more than a day</span>;
  }
  return <Duration duration={duration} />;
};

const ifNotZero = (num, suffix) => (num > 0 ? num + suffix : null);
const Duration = ({ duration }) => {
  const hours = Math.floor(duration / (60 * 60));
  const minutes = Math.floor((duration - hours * 60 * 60) / 60);

  if (hours === 0 && minutes === 0) {
    return <span>{duration + "s"}</span>;
  }

  const hoursStr = ifNotZero(hours, "h");
  const minutesStr = ifNotZero(minutes, "m");
  return <span>{[hoursStr, minutesStr].filter((str) => str !== null).join(" ")}</span>;
};

const IdLabel = ({ id }) => <span> [{id.substring(0, 4)}]</span>;

const BoobButton = ({ contentCollection, side, inProgressSide }) => {
  const thisSideInProgress = inProgressSide !== undefined && side.toLowerCase() === inProgressSide.side;
  const otherSideInProgress = inProgressSide !== undefined && !thisSideInProgress;

  return (
    <Button
      size="lg"
      variant={thisSideInProgress ? "danger" : "success"}
      disabled={otherSideInProgress}
      onClick={() => {
        const now = timestampNow();
        if (thisSideInProgress) {
          contentCollection.doc(inProgressSide.id).set({ endTime: now }, { merge: true });
        } else {
          contentCollection.add({
            startTime: now,
            side: side.toLowerCase(),
          });
        }
      }}
    >
      {thisSideInProgress ? (
        <span>
          {side}
          <br />
          <FontAwesome.Stop />
        </span>
      ) : (
        <span>
          {side}
          <br />
          <FontAwesome.Start />
        </span>
      )}
    </Button>
  );
};

function toIso(date) {
  return new Date("" + date.substring(6, 10) + "-" + date.substring(3, 5) + "-" + date.substring(0, 2) + "T" + date.substring(11, 16) + ":00Z");
}

const Import = ({ newObjectSink }) => {
  const [textContent, setTextContent] = useState("");

  const newObjects = textContent
    .split(/[\r\n]+/)
    .map((entry) => entry.trim())
    .filter((entry) => entry.length > 0)
    .map((entry) => {
      const splitByTabs = entry.split(/\t/);
      return {
        startTime: dateToTimestamp(toIso(splitByTabs[0])),
        endTime: dateToTimestamp(toIso(splitByTabs[1])),
        side: splitByTabs[2],
      };
    });

  return (
    <div>
      <h1>Import</h1>
      <Form>
        <Form.Group controlId="namesInput">
          <Form.Label column={false}>Paste here:</Form.Label>
          <Form.Control as="textarea" rows="20" value={textContent} onChange={(event) => setTextContent(event.target.value)} />
        </Form.Group>
      </Form>
      <Button onClick={() => newObjectSink(newObjects).then(() => setTextContent(""))}>Import</Button>
    </div>
  );
};

const formatDateForExport = (timestamp) => timestamp.toDate().toISOString();
const formatRowForExport = (doc) => formatDateForExport(doc.data().startTime) + "," + formatDateForExport(doc.data().endTime) + "," + doc.data().side;
const Export = ({ contentDocs }) => {
  return (
    <Button
      onClick={() => {
        console.log("StartTime,EndTime,Side");
        contentDocs
          .filter((doc) => doc.data().endTime !== undefined)
          .sort((feed1, feed2) => feed2.data().startTime._compareTo(feed1.data().startTime))
          .map(formatRowForExport)
          .forEach((row) => console.log(row));
      }}
    >
      Export to console
    </Button>
  );
};

export default LoggedInApp;

import React from "react";

const Emoji = ({ label, char }) => (
  <span role="img" aria-label={label}>
    {char}
  </span>
);

Emoji.Sleep = () => <Emoji label="Sleep" char="🌙" />;
Emoji.Nap = () => <Emoji label="Nap" char="💤" />;
Emoji.Feed = () => <Emoji label="Feed" char="🤱" />;
Emoji.Question = () => <Emoji label="Question" char="❓" />;

export default Emoji;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import LoggedInApp from "./components/feeding/LoggedInApp";
import * as serviceWorker from "./serviceWorker";
import initFirebaseAppComponent from "@wellingtonsteve/jscommon/initFirebaseAppComponent";

const FirebaseApp = initFirebaseAppComponent({
  apiKey: "AIzaSyA3NxY6BOzKLES5ZxAeSKoDj-Qy595F5hc",
  authDomain: "feeding-5d46b.firebaseapp.com",
  databaseURL: "https://feeding-5d46b.firebaseio.com",
  projectId: "feeding-5d46b",
  storageBucket: "feeding-5d46b.appspot.com",
  messagingSenderId: "252239219720",
  appId: "1:252239219720:web:ada23b48d6f73d8fd8d5bd",
});

ReactDOM.render(
  <FirebaseApp>
    <LoggedInApp />
  </FirebaseApp>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
